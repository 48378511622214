/*****************************
  banner
*****************************/
.banner h1{
	color: $white;
	font-size:72px;
	line-height: 82px;
	margin-bottom: 20px;
}

.banner h6{
	color: $white;
	margin-bottom: 30px;
}

.swiper-button-prev, .swiper-button-next{
	background-image: none;
	opacity: 0.5;
	transition: all 0.3s ease-in-out;
}

.swiper-button-prev{
	left: 5%;
    .icon-btn{
      display: flex;
      width: 50px;
      height: 50px;
      text-align: center;
      border: 2px solid $white;
      background: none;
      border-radius: 50%;
      align-items: center;
      align-self: center;
      justify-content: center;
      color: $white;
    }
}

.swiper-button-next{
	right: 5%;
    .icon-btn{
      display: flex;
      width: 50px;
      height: 50px;
      text-align: center;
      border: 2px solid $white;
      background: none;
      border-radius: 50%;
      align-items: center;
      align-self: center;
      justify-content: center;
      color: $white;
    }
}

.swiper-button-prev:hover, .swiper-button-next:hover{
	opacity: 1;
}

.banner ul {
  padding: 0;
  margin: 30px 0 50px;
  list-style-type: none;
  text-align: center;
}

.banner ul li {
  display: inline-block;
}

.banner ul li a {
  color: $white;
  font-size: 18px;
  font-weight: 600;
  border-right: 2px solid $white;
  padding: 0 15px;
}

.banner ul li a:hover{
	color: $primary;
}

.banner ul li:last-child a {
	border-right: 0;
}


/*************************
       Responsive
*************************/

@media (max-width: 991px) {

  .banner h1 {
    font-size: 66px;
    line-height: 76px;
    margin-bottom: 10px;
  }

  .swiper-button-prev{
    left: 5%;
  }

  .swiper-button-next{
    right: 5%;
  }

}

@media (max-width: 767px) {

  .banner h1 {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 10px;
  }

}
