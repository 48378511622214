/*****************************
  Category
*****************************/

/* Transition */
.category-grid-style-01 .category-item .category-icon,
.category-grid-style-01 .category-item:before,
.category-list-style-01 ul,
ul.category-list li a:after,
.category-box .category-icon,
.category-box:hover .category-icon i,
.category-box:hover .category-icon .category-title,
.category-box-style-02,
.category-box-style-02:before,
.category-box-style-02 ul.category-list {
	transition: all 0.3s ease-in-out;
}

/* List Style 01 */
.category.category-list-style-01{
	border: 5px solid $primary;
}
.category-list-style-01:before{
	content: "";
  position: absolute;
  border: 5px solid $gray-9;
  right: 15px;
  height: 200px;
  top: 15%;
	}
.category-list-style-01 ul.category-list {
	margin-bottom: 0;
	padding: 0 0 15px;
	height: 100%;
	border-bottom: 2px solid rgba($white, 0.1);
}

.category-list-style-01 ul.category-list:hover {
	border-bottom-color: rgba($white, 0.3);;
}

ul.category-list {
	padding-left: 0;
	margin-bottom: 0;
	li {
		list-style: none;
		margin-bottom: 15px;
		a {
			display: inline-block;
			color: $white;
			&:hover {
				color: $primary;
				&:after {
					margin-left: 10px;
					opacity: 1;
				}
			}
			&:after {
				content: '\f105';
				font-family: "Font Awesome 5 Free";
				font-size: 14px;
				font-weight: bold;
				margin-left: -5px;
				opacity: 0;
			}
		}
	}
}

.dark-background {
	ul.category-list {
		li {
			a {
				color: $white;
				&:hover {
					color: $primary;
				}
			}
		}
	}
}

/* Category Box Style 01 */
.category-box {
	.category-icon {
		background: $white;
		padding: 40px 30px;
		margin-bottom: 30px;
		text-align: center;
		box-shadow: $boxshadow-lg;
		border-radius: $border-radius-50;
		margin: auto;
		width: 190px;
		height: 190px;
		margin-bottom: 20px;
		i {
			color: $primary;
			font-size: 80px;
			margin-bottom: 20px;
		}
	}
	&:hover {
		.category-icon {
			background: $gray-9;
			i {
				color: $white;
			}
			.category-title {
				color: $white;
			}
		}
	}
	ul.category-list {
		li {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	p {
		font-size: 16px;
		text-align: center;
		color: $black;
		margin-bottom: 0;
	}
}


/* Category Box Style 02 */
.category.category-list-style-02{
	border: 5px solid $primary;
}
.category-list-style-02:before{
	content: "";
  position: absolute;
  border: 5px solid $white;
  right: 15px;
  height: 200px;
  top: 15%;
	}
.category-box-style-02 {
	padding: 60px 5px;
	border-radius: $border-radius-0;
	transform: scale(0.98);
	&:hover {
		background: $gray-9;
		box-shadow: $boxshadow-lg;
		transform: scale(1);
		.category-icon {
			background: transparent;
		}
		ul.category-list {
			opacity: 1;
		}
	}
	.category-icon {
		background: transparent;
		box-shadow: none;
		padding: 0;
	}
	ul.category-list {
		opacity: 0;
		li {
			a {
				color: $white;
				&:hover {
					color: $primary;
				}
			}
		}
	}
}

.category-box-style-02.active {
	background: $gray-9;
	box-shadow: $boxshadow-lg;
	transform: scale(1);
	.category-icon {
		background: transparent;
		i {
			color: $white;
		}
		.category-title {
			color: $white;
		}
	}
	ul.category-list {
		opacity: 1;
	}
}

/* category-style-03 */
.category-style-03{
		display: flex;
   	flex-wrap: wrap;
	a.category-item{
		padding: 30px 30px 15px 30px;
		border:  2px solid rgba($white, 0.1);
    margin: 0px 10px 10px 10px;
    width: 160px;
    &:hover{
    	background: $primary;
    }
	}
}

/*****************************
	Responsive
*****************************/

@media (max-width: 575px) {
	.category-style-03 a.category-item{
		width: 200px;
	}
}
