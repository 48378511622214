/*****************************
  Form Control
*****************************/

/* form-control */
.form-control {
  border: 1px solid $border-color;
  border-radius: 0px;
  height: 48px;
  padding: 10px 20px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  background:$white;
  color: $gray-8;
  border-radius: $border-radius-0;
  background-clip: inherit !important;

  &:focus {
    box-shadow: none;
    border-color: $primary;
  }

}

.form-control::-moz-placeholder {
  color: $gray-5;
}

.form-control::-ms-input-placeholder {
  color: $gray-5;
}

.form-control::-webkit-input-placeholder {
  color: $gray-5;
}


/* checkbox */
.custom-control-input {
  &:checked~.custom-control-label {
    &:before {
      background: $primary;
      border-color: $primary;
    }

  }

  &:not(:disabled) {
    &:active~.custom-control-label {
      &:before {
        background: transparent;
        border-color: transparent;
      }

    }

  }

  &:focus~.custom-control-label {
    &:before {
      box-shadow: none;
    }

  }

  &:focus {
    &:not(:checked)~.custom-control-label {
      &:before {
        border-color: $border-color;
      }

    }

  }

}

.custom-control-label {
  &:before {
    top: 0px;
  }

  &:after {
    top: 0px;
  }

}

.custom-checkbox {
  .custom-control-label {
    &:before {
      border-radius: $border-radius-0;
      border: 2px solid $gray-2;
    }

  }

}

