/*****************************
  Countdown
*****************************/

.countdown {
	padding: 20px 10px;
	display: inline-block;
  text-align: center;
  margin: 0 15px;

	span {
		font-size: 54px;
		line-height: 60px;
		font-weight: 600;
		color: $white;
	}

	p {
		font-size: 18px;
		text-transform: capitalize;
		margin-bottom: 0;
		color: $gray-8;
	}

}

/*****************************
	responsive
*****************************/

@media (max-width: 992px) {

	.countdown {
		span {
			font-size: 36px;
			line-height: 40px;
		}
	}

}

@media (max-width: 767px) {

.countdown {

	span {
			font-size: 32px;
			line-height: 40px;
		}

		p {
			font-size: 16px;
		}

	}

}
