/*****************************
  Case Study
*****************************/
.case-study {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  .case-study-title {
    display: block;
    color: $gray-8;
    font-size: 18px;
    font-weight: 500;
    &:hover {
      color: $primary;
    }
  }
  .case-study-services {
    display: block;
    margin-bottom: 10px;
    &:hover {
      color: $gray-9;
    }
  }
  .case-study-img {
    background-size: cover;
    min-height: 249px;
    border-radius: $border-radius-0;
    background-position: center center;
  }
  .case-study-img.case-study-lg {
    min-height: 530px;
  }
  .case-study-info {
    padding: 20px;
    width: 90%;
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 2;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, 0%);
    background: $white;
    p {
      max-height: 66px;
      overflow: auto;
    }

  }
  &:hover {
    .case-study-info {
      opacity: 1;
      bottom: 40px;
    }
  }
  .case-study-lg {
    + {
      .case-study-info {
        p {
          max-height: 340px;
          overflow: auto;
        }
      }
    }
  }
}

/* style 02 */

.case-study-style-02{
  position: relative;

  &:before {
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    top: 0;
    content: "";
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    &:before {
    height: auto;
    transition: all 0.4s ease-in-out;
    }
  }
  .case-study-info{
    background: $white;
    border-radius: 3px;
      p {
        color: $white;
      }
    .case-study-services{
    color: $white;
      &:hover {
        color: $white;
      }
    }
    .case-study-title {
    color: $white;
    &:hover{
      color: $white;
    }
  }
  }
  .icon-btn {
    opacity: 1;
    position: absolute;
    top: 10px;
    z-index: 99;
    right: 5px;
  }
}

.clients-detail {
  margin: 0;
  li {
    padding-right: 130px;
    &:last-child {
      padding-right: 0px;
    }
  }
}


/*****************************
  responsive
*****************************/

@media (max-width: 1199px) {
  .clients-detail li {
        padding-right: 90px;
    }
}

@media (max-width: 991px) {
	.case-study .case-study-info {
		padding: 20px;
	}
  .clients-detail li {
        padding-right: 50px;
        margin-top: 0px;
    }
}

@media (max-width: 767px) {
    .clients-detail li {
        padding-right: 50px;
        width: 50%;
        float: left;
    }
}

@media (max-width: 575px) {
    .clients-detail li {
        padding-right: 0px;
        width: 100;
        float: none;
    }
}
