/*****************************
  Typography
*****************************/

body {
	font-family: $font-base;
	font-weight: normal;
	font-style: normal;
	font-size: 14px;
	color: $body-color;
	overflow-x: hidden;
}

a {
	outline: medium none !important;
	color: $primary;
	transition: all 0.3s ease-in-out;

	&:focus {
		color: $gray-8;
		text-decoration: none !important;
	}

	&:hover {
		color: $gray-8;
		text-decoration: none !important;
	}

}

a.text-dark {
	&:focus {
		color: $primary !important;
	}
	&:hover {
		color: $primary !important;
	}

}

input {
	outline: medium none !important;
	color: $primary;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6
 {
	font-family: $font-hedding;
	font-weight: normal;
	color: $gray-8;
	margin: 0px 0px 1rem;
	font-weight: bold;
	line-height: 1.2;

	a {
		color: inherit;
	}

}
iframe { width: 100%; }

label {
	font-weight: normal;
}

h1 {
	font-size: 60px;
	font-style: normal;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 24px;
	font-weight: 500;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
	line-height: 1.5;
	font-weight: 500;
}

p {
	line-height: 1.8;
}

*::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

/* container-fluid */
.container-fluid.container-space {
	padding: 0 100px;
}

/* border */
.border {
	border-color: $border-color !important;
}

.border-top {
	border-top-color: $border-color !important;
}

.border-left {
	border-left-color: $border-color !important;
}

.border-right {
	border-right-color: $border-color !important;
}

.border-bottom {
	border-bottom-color: $border-color !important;
}

.border-radius {
	border-radius: 0px !important;
}
.border-radius-0 {
	border-radius: 0px !important;
}

/* badge */
.badge {
	border-radius: $border-radius-0;
	font-weight: 500;
	+ {
		.badge {
			margin-left: 6px;
		}
	}
}

.badge-md {
	padding: 5px 8px;
	font-size: 13px;
	font-weight: normal;
}

.badge-primary {
	background: $primary;
}

/* back-to-to */
.back-to-top {
	background: $primary;
	color: $white;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	display: inline-block;
	&:hover {
		color: $white;
	}
	&:focus {
		color: $white;
	}
}


/* pagination */
.pagination {
	.page-item {
		.page-link {
			padding: 16px 25px;
			color: $gray-9;
			border-color: rgba($gray-9, 0.1);
			border-radius: 0px;
			&:hover {
				background: $primary;
				color: $white;
			}
			&:focus {
				box-shadow: none;
			}
		}
	}
	.page-item.active {
		.page-link {
			background: $primary;
			border-color: $primary;
			color: $white;
		}
	}
}

/* blockquote */
.blockquote {
	font-size: 16px;
	border-left: 10px solid $primary;
	padding-left: 30px;
	font-style: italic;
	display: block;
}
